<template>
  <main
    v-if="!!state"
    :class="[
      { 'btn-primary': state === 'primary' },
      { 'btn-secondary': state === 'secondary' },
    ]"
  >
    <p class="-xstr">
      {{ title }}
    </p>
  </main>
</template>

<script>
export default {
  props: ["state", "title"],
};
</script>

<style scoped>
.btn-primary,
.btn-secondary {
  height: 100%;
  width: 100%;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  transition: background-position 1s, box-shadow 800ms;
  background-size: 500% auto;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 16px;
  padding-block: 8px;
}
.btn-primary {
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
  color: var(--white1);
}
.btn-secondary {
  background-image: linear-gradient(
    to right,
    var(--secondary) 0%,
    var(--secondary-highlight) 51%,
    var(--secondary) 100%
  );
  color: var(--white1);
}
.btn-primary:active,
.btn-secondary:active {
  scale: 0.95;
}

.btn-primary:hover,
.btn-secondary:hover {
  background-position: right center;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
</style>